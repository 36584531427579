import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"


export default function AddressForm({ address, setAddress }) {
  const [county, setCounty] = React.useState(address?.county || "")
  const [street, setStreet] = React.useState(address?.street || "")

  React.useEffect(() => {
    setAddress({ county, street })
  }, [street, county])
  return (
    <React.Fragment>
      <Typography
        variant="h6"
        gutterBottom
        style={{ fontFamily: "oswald", color: "#1d9682" }}
      >
        Shipping address
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="county"
            name="county"
            label="County"
            fullWidth
            value={county}
            onChange={e => setCounty(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="street"
            name="street"
            label="Street/estate/road"
            fullWidth
            value={street}
            onChange={e => setStreet(e.target.value)}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
