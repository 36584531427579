import React, { useState } from "react"
import styled from "styled-components"
import { Modal } from "react-bootstrap"
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import Button from "@material-ui/core/Button"
import Link from "@material-ui/core/Link"
import Typography from "@material-ui/core/Typography"
import AddressForm from "../components/checkout/AddressForm"
import PaymentForm from "../components/checkout/PaymentForm"
import Review from "../components/checkout/Review"
import {
  StyledButton,
  StyledForm,
  StyledIcon,
  StyledInput,
} from "../components/providerSection/providerElements"
import PageNav from "../components/homeNav/PageNav"
import axios from "axios"
import useCartState from "../stores/cart"
import useAuthState from "../stores/auth"

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="#">
        Afyabook
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  )
}

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    height: "100vh",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    boxShadow: "0px 10px 20px #a3d4cb",
    borderRadius: "10px",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(0.5),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
    fontFamily: "Oswald",
    "& .MuiStepIcon-active": { color: "#1d9682" },
    "& .MuiStepIcon-completed": { color: "lightgreen" },
    "& .Mui-disabled .MuiStepIcon-root": { color: "gray" },
  },
  customStepLabel: {
    fontFamily: "Oswald",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
    margin: "20px",
  },
  button: {
    margin: "auto",
    marginRight: "10px",
    "&:hover": {
      backgroundColor: "#1d968254",
    },
  },
}))
const CheckoutNavBtn = styled.a`
  background: #1d9682;
  box-shadow: 2px 4px 25px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  white-space: nowrap;
  color: white;
  font-family: oswald;
  font-weight: 600;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  text-align: center;
  &:hover {
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    background: #f5f5f5;
    color: #205072;
  }
`

export default function Checkout() {
  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const [show, setShow] = useState(false)

  const { user, setUser } = useAuthState()

  const handleNext = () => {
    setError("")
    if (activeStep == 0 && (!address?.county || !address?.street)) {
      setError("All fields are required")
    } else {
      setActiveStep(activeStep + 1)
    }
  }

  const handleClose = () => {
    setShow(false)
    setErrorM("")
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }

  React.useEffect(() => {
    if (activeStep == 3) {
      sendOrder()
    }
  }, [activeStep])

  React.useEffect(() => {
    if (activeStep == 2 && !user.phoneNumber) {
      setShow(true)
    }
  }, [activeStep])

  const { cart, setCart, getCartLength } = useCartState()

  const [address, setAddress] = useState({
    county: user?.physicalAddress?.county || "",
    street: user?.physicalAddress?.street || "",
  })
  const [paymentMethod, setPaymentMethod] = useState("mpesa")
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || "")

  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState("")
  const [loading, setLoading] = useState(false)

  const [submitted, setSubmitted] = useState(false)
  const [errorM, setErrorM] = useState("")
  const [generalComment, setGeneralComment] = useState("");

  const handleCommentChange = (comment) => {
      setGeneralComment(comment);
    }

  

  //console.log("user",phoneNumber)

  const uploadData = async () => {
    try {
      setErrorM("")
      if (!phoneNumber) {
        setErrorM("Missing required fields")
      } else {
        setLoading(true)

        const data = {
          phoneNumber,
        }
        const result = await axios.patch(
          `${process.env.GATSBY_API_URL}/users/${user._id}/phoneNumber`,
          {
            ...data,
          }
        )

        setUser(result.data)

        setLoading(false)
        setSubmitted(true)
      }
    } catch (err) {
      console.log(
        "🚀 ~ file: EditProduct.js ~ line 54 ~ editProduct ~ error",
        err
      )
      setLoading(false)
      const { message } = err.response.data
      setErrorM(message)
    }
  }

  // const sendOrder = async () => {
  //   const products = cart.map(item => {
  //     return {
  //       id: item.id,
  //       quantity: item.quantity,
  //       prescription: item?.prescription,
  //     }
  //   })
  //   console.log("prodcts",products)
  //   if (!!getCartLength()) {
  //     return setError("Cart is empty")
  //   } else {
  //     try {
  //       const response = await axios.post(`${process.env.GATSBY_API_URL}/orders`, {
  //         products,
  //         patientId: user.id,
  //         address,
  //         paymentMethod,
  //       })

  //       console.log("response", response)

  //       setCart({})
  //     } catch (error) {
  //       console.error("Error making POST request:", error);
  //       const message = error.response.data.message
  //       setError(message)
  //     }
  //   }
  // }

  const sendOrder = async () => {
    const products = cart.map(item => ({
      id: item.id,
      quantity: item.quantity,
      prescription: item?.prescription || null // Ensure prescription is either present or null
    }));
  
    if (getCartLength() === 0) {
      return setError("Cart is empty");
    }
  
    const orderData = {
      products,
      patientId: user.id,
      address: {
        county: address.county || '',
        street: address.street || ''
      },
      paymentMethod,
      generalComment: generalComment || '' // Ensure it's an empty string if not provided
    };
  
    console.log("Sending order data:", JSON.stringify(orderData, null, 2)); // Pretty print for clarity
  
    try {
      const response = await axios.post(`${process.env.GATSBY_API_URL}/orders`, orderData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      console.log("Order response:", response.data); // Log the response data
      setCart([]);
    } catch (error) {
      console.error("Error making POST request:", error);
      const message = error.response?.data?.message || "An error occurred";
      setError(message);
    }
  };
  
  
  

  const steps = ["Shipping address", "Payment details", "Review your order"]

  // steps switch case
  function getStepContent(step) {
    switch (step) {
      case 0:
        return <AddressForm address={address} setAddress={setAddress} />
      case 1:
        return (
          <PaymentForm
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
          />
        )
      case 2:
        return <Review address={address} paymentMethod={paymentMethod} onCommentChange={handleCommentChange} />
      default:
        throw new Error("Unknown step")
    }
  }

  return (
    <React.Fragment>
      <PageNav />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography
            component="h1"
            variant="h4"
            align="center"
            style={{ fontFamily: "oswald", color: "#1d9682" }}
          >
            Checkout
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel classes={{ label: classes.customStepLabel }}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                {error ? (
                  <>
                    <Typography
                      variant="h5"
                      gutterBottom
                      style={{ fontFamily: "oswald" }}
                    >
                      Error ordering from Afyabook.
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      style={{ fontFamily: "oswald" }}
                    >
                      We encountered an error trying to order from Afyabook,
                      please try again.
                    </Typography>
                    <CheckoutNavBtn to="/pharmacy">Back to home</CheckoutNavBtn>
                  </>
                ) : (
                  <>
                    <Typography
                      variant="h5"
                      gutterBottom
                      style={{ fontFamily: "oswald" }}
                    >
                      Thank you for shopping with Afyabook.
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      style={{ fontFamily: "oswald", marginBottom: "30px" }}
                    >
                      You will be contacted through mail or phone with order
                      processing details. Check order status{" "}
                      <a href="/Orders">here</a>
                    </Typography>
                    <CheckoutNavBtn href="/pharmacy">
                      Back to home
                    </CheckoutNavBtn>
                  </>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <p style={{ color: "red" }}>{error}</p>
                {getStepContent(activeStep)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} className={classes.button}>
                      Back
                    </Button>
                  )}

                  <CheckoutNavBtn onClick={handleNext}>
                    {activeStep === steps.length - 1 ? "Place order" : "Next"}
                  </CheckoutNavBtn>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        <Modal
          show={show}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <StyledForm>
            <>
              <p style={{ color: "red" }}>{errorM}</p>
              {/* <StyledIcon onClick={handleClose} />                                         */}
              <p style={{ fontSize: "1.5em" }}>
                Kindly provide your Phone Number so we can contact you easily.{" "}
              </p>
              <br />
              <label htmlFor="phoneNumber">Phone Number</label>
              <StyledInput
                type="tel"
                name="Phone Number"
                value={phoneNumber}
                onChange={e => {
                  setPhoneNumber(e.target.value)
                }}
              />
              <StyledButton
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                disabled={submitted || loading || !phoneNumber}
                onClick={() => {
                  uploadData()
                  handleClose()
                }}
              >
                Submit
              </StyledButton>{" "}
            </>
          </StyledForm>
        </Modal>
        <Copyright />
      </main>
    </React.Fragment>
  )
}
